// /**
//  * YEBOYEBO Google Tag Manager compatibility for ScandiPWA
//  * @copyright Yeboyebo, Inc. All rights reserved.
//  */
// //import TagManager from 'react-gtm-module';

// const gogleTagManagerEvent = (args, callback, instance) => {
//     console.log("url resolver plugin");
//     const { urlRewrite: prevUrlRewrite } = args[0];
//     const { urlRewrite  } = instance.props;
//     if (urlRewrite?.additional_data && !prevUrlRewrite?.additional_data) {
//         // console.log(instance.props.urlRewrite);
//         // console.log(instance.props);
//         if (instance.props.urlRewrite?.additional_data) {
//             console.log("appendchild");
//             console.log(JSON.parse(instance.props.urlRewrite.additional_data.hrefLang));
//             // const parser = new DOMParser();
//             // const newDocument = parser.parseFromString(instance.props.urlRewrite.additional_data.hrefLang, "text/html");
//             // const children = newDocument.head.children;
//             // console.log(children);
//             // console.log(newDocument.head);
//             // for(let i = 0; i < children.length; i++) {
//             //     //console.log(children[i]);
//             //     document.head.appendChild(children[i]);
//             //     console.log(document.head);
//             // }
//             // children.forEach(child => {
//             //     document.head.appendChild(child);
//             // });
            
//         } else {
//             console.log("no tengo");
//         }
//     }

//     return callback(...args);
// };

// export default {
//     'Route/UrlRewrites/Container': {
//         'member-function': {
//             componentDidUpdate: gogleTagManagerEvent
//         }
//     }
// };

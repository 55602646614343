// /**
//  * YEBOYEBO Google Tag Manager compatibility for ScandiPWA
//  * @copyright Yeboyebo, Inc. All rights reserved.
//  */
// import ReactPixel from 'react-facebook-pixel';

// const facebookPixelAddToCartEvent = (args, callback, instance) => {
//     const fbq = window.fbq;
//     if (fbq) {
//         const localStorageConfig = JSON.parse(window.localStorage.config);
//         const { product: currentProduct }= instance.props;
//         const currencyCode = localStorageConfig.data.currency.base_currency_code;  
//         const pixelArgs = {
//             curency: currencyCode,
//             value: currentProduct.price_range.minimum_price.final_price.value,
//             content_type: 'product',
//             contents: [
//                 {
//                     id: currentProduct.sku,
//                     quantity: 1,
//                     item_price: currentProduct.price_range.minimum_price.final_price.value
//                 }
//             ]
//         }
//         ReactPixel.fbq('track', 'AddToCart', pixelArgs, { eventID: generateEventId('AddToCart') });
//     }
//     return callback(...args);
// };

// function stringToHash(string)
// {
//     let hash = 0;
      
//     if (string.length == 0) return hash;
      
//     for (let i = 0; i < string.length; i++) {
//         const char = string.charCodeAt(i);
//         hash = ((hash << 5) - hash) + char;
//         hash = hash & hash;
//     }
    
//     return String(hash);
// }

// function generateEventId(eName)
// {
//     const uCookie = document.cookie;
//     const uHash = stringToHash(uCookie);
//     const url = window.location.href;
//     const urlHash = stringToHash(url);
    
//     function getTimeStamp() {
//         if (!Date.now) {
//             Date.now = function() { return new Date().getTime(); }
//         }
        
//         return Date.now();
//     }
    
//     const timestamp = String(getTimeStamp());
    
//     return eName + uHash + urlHash + timestamp;
// }

// export default {
//     'Component/AddToCart/Container': {
//         'member-function': {
//             addProductToCart: facebookPixelAddToCartEvent
//         }
//     }
// };
